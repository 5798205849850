import { faCompressAlt, faExpandAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useWidgetUiContext } from '../WidgetUiContext'

export default function MinMaxBtn() {
	const { handleMinimize } = useWidgetUiContext()

	return (
		<span
			className="restart-icon btn-mazimize"
			onClick={() => {
				handleMinimize()
				// refreshRendor()
			}}>
			<FontAwesomeIcon className="btn-mazimize-icon" color="white" icon={faExpandAlt} />
			<FontAwesomeIcon className="btn-nimimize-icon" color="white" icon={faCompressAlt} />
		</span>
	)
}
