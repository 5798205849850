import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Store } from 'redux'
import { selectBotToken, useAppSelector } from 'src/redux/hooks'
import { useWidgetUiContext } from '../WidgetUiContext'
import { ChatInfo } from '../../interfaces'

interface SendBoxClickToBeginProps {
	notificationLabel: string
	store: Store
	pgCtx: any
	userId: string
	setClickToBegin: (b: boolean) => void
	setHideSendBox: (b: boolean) => void
}
function SendBoxClickToBegin({
	notificationLabel,
	store,
	pgCtx,
	userId,
	setClickToBegin,
	setHideSendBox,
}: SendBoxClickToBeginProps) {
	const { t } = useTranslation()
	const { conversationId, ls } = useWidgetUiContext()
	const botToken = useAppSelector(selectBotToken)

	const handleClickToBegin = useCallback(() => {
		setClickToBegin(false)
		setHideSendBox(false)

		if (store) {
			store.dispatch({
				type: 'WEB_CHAT/SEND_MESSAGE',
				payload: { text: t('Hi') },
			})
			const storeConversation = {
				chatConversationId: conversationId,
				timestamp: Date.now(), // moment .utc(),
				pgCtx,
				userId,
				token: botToken,
			}

			// No record of chatConversationId means new convo
			const chatInfo = ls.get(pgCtx || 'chatInfo') as ChatInfo
			const isNewConvo = !chatInfo?.chatConversationId

			isNewConvo && (pgCtx ? ls.set(pgCtx, storeConversation) : ls.set(pgCtx || 'chatInfo', storeConversation))
			
			//  widget2-3-0
			// !conversationId && (pgCtx ? ls.set(pgCtx, storeConversation) : ls.set('chatInfo', storeConversation))
			
			localStorage.removeItem('_secure__ls__metadata')
		}
	}, [setClickToBegin, setHideSendBox, conversationId])

	return (
		<div className="click-begin">
			<span onClick={handleClickToBegin}>{notificationLabel ? t(notificationLabel) : t('Click To Begin')}</span>
		</div>
	)
}
export default SendBoxClickToBegin
