import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useWidgetUiContext } from '../WidgetUiContext'

interface CloseBtnProps {
	botStudioBot?: boolean
}
function CloseBtn({ botStudioBot }: CloseBtnProps) {
	const { setChatContainerClasses } = useWidgetUiContext()
	const handleCloseBot = () => {
		window.parent.postMessage('botClose', '*')
		if (botStudioBot) {
			return setChatContainerClasses('close-bot')
		}

		const el = document.querySelector('.chat-container')
		const el1 = document.querySelector('.bot-circle > .bot-btn')
		const elm = document.querySelector('.bot-icon-main')
		if (el && el.classList) {
			el.classList.add('close-bot')
			el1?.classList?.remove('ch-tab')
		}
		if (elm && elm.classList) {
			elm.classList.add('chat-close')
		}
		return null
	}

	return (
		<span className="restart-icon btn-close" onClick={handleCloseBot}>
			<FontAwesomeIcon color="white" icon={faTimes} />
		</span>
	)
}
export default CloseBtn
