import React from 'react'
import { useTranslation } from 'react-i18next'
import { Store } from 'redux'
import { useWidgetUiContext } from '../WidgetUiContext'

interface RestartBtnProps {
	store: Store
}
function RestartBtn({ store }: RestartBtnProps) {
	const { t } = useTranslation()
	const { restartIcon } = useWidgetUiContext()

	const handleRestart = () => {
		if (store) {
			store.dispatch({
				type: 'WEB_CHAT/SEND_MESSAGE',
				payload: { text: t('Restart') },
			})
		}
	}

	return (
		<span className="restart-icon" onClick={handleRestart}>
			<img src={restartIcon} />
		</span>
	)
}
export default RestartBtn
