import { faPaperclip } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import axios from 'axios'
import pica from 'pica'
import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useWidgetUiContext } from 'src/app/bot/components/WidgetUiContext'
import styled from 'styled-components'

const resizer = pica({
	// features: ["js", "wasm", "ww"]
	// features: ["ww", "js"]
})

interface UploadProps {
	bg: string
	color: string
	userId: string
	username: string
	customMessage: string
	store: any
	directLine: any
	aiBot: any
}
export default function Upload({ bg, color = '#fff', store, directLine, userId, username, customMessage, aiBot }) {
	const { t } = useTranslation()
	const { conversationId } = useWidgetUiContext()

	const FILE_SIZE_MB = 4 // In MB
	const hiddenFileInput = useRef(null)
	const [title, setTitle] = useState(t(`File only support ${FILE_SIZE_MB}mb size except images`))
	const [progress, setProgress] = useState(false)
	const handleClick = (_event: unknown) => {
		hiddenFileInput.current.click()
	}

	const postFileUpload = ({ fileName, fileType, contentUrl, base64Url = '' }) => {
		directLine
			.postActivity({
				from: { id: userId, name: username },
				type: 'message',
				attachments: [
					{
						contentType: fileType,
						contentUrl,
						name: fileName,
						thumbnailUrl: base64Url,
					},
				],
			})
			.subscribe(
				id => console.log('Posted activity, assigned ID ', id),
				error => {
					const { response } = error
					const message = (response.error && response.error.message) || ''
					showAlert({ message, level: 'error' })
				}
			)
	}

	const sendFileByApi = async ({ fileName, fileType, file }) => {
		// let fileName = file.name;
		// let fileType = file.type;
		const axiosInstance = axios.create({
			baseURL: directLine.domain || 'https://directline.botframework.com/v3/directline/',
			timeout: 30000,
			headers: {
				Authorization: `Bearer ${directLine.token}`,
				'Content-Type': fileType,
				'Content-Disposition': `name="file"; filename="${fileName}"`,
			},
		})
		const data = new FormData()
		data.append('file', file)
		const url = `conversations/${conversationId || directLine.conversationId}/upload?userId=${userId}`
		return axiosInstance.post(url, data)
	}

	const checkFileSize = fileSize => {
		const inMbSize = fileSize / 1024 / 1024
		const inValid = inMbSize > FILE_SIZE_MB
		return inValid
	}

	const showAlert = ({ message, level = 'info' }) => {
		store.dispatch({
			type: 'WEB_CHAT/SET_NOTIFICATION',
			payload: {
				data: { accepted: false },
				id: 'upload-info-msg',
				level,
				message: message || t(`File maximum size exceed(${FILE_SIZE_MB})`),
			},
		})
		hiddenFileInput.current.value = null
	}

	const handleChange = async event => {
		const { files } = event.target
		if (files && files.length > 1) {
			const message = t(`Please choose one file at a time`)
			showAlert({ message })
			return
		}
		const file = files[0]
		if (!file) return
		setProgress(true)
		const compFile = file.type.indexOf('image/') != -1 ? await compressFile(file) : file
		const fileSize = compFile.size
		if (checkFileSize(fileSize)) {
			const message = t(`File size exceeds ${FILE_SIZE_MB}MB Please try a smaller size ${customMessage}`)
			showAlert({ message, level: 'error' })
			setProgress(false)
			return
		}
		await sendFileByApi({ fileName: file.name, fileType: file.type, file: compFile })
		hiddenFileInput.current.value = null
		setProgress(false)
	}

	const compressFile = async file => {
		const dataUrl = URL.createObjectURL(file)
		const imgCanavas = await asyncImage(dataUrl)
		const reziedCanavasImg = await resizeImage(imgCanavas)
		const outputFile = await resizer.toBlob(reziedCanavasImg, file.type)
		const newFile = new File([outputFile], file.name, {
			type: file.type,
		})
		return newFile
	}

	const asyncImage = (src, ...constructorProps) =>
		new Promise((resolve, reject) => {
			const img = new Image(...constructorProps)
			img.onload = () => resolve(img)
			img.setAttribute('crossOrigin', '')
			img.onerror = reject
			img.src = src
		})

	const resizeImage = image => {
		const newDimensions = calculateAspectRatioFit(image.naturalWidth, image.naturalHeight, 1024, 768)
		const offScreenCanvas = document.createElement('canvas')
		offScreenCanvas.width = newDimensions.width
		offScreenCanvas.height = newDimensions.height

		return resizer.resize(image, offScreenCanvas, {
			quality: 3, // this is the max (recommended)
			alpha: true, // use alpha channel
			unsharpAmount: 20,
			unsharpRadius: 0.6,
			unsharpThreshold: 2,
			transferable: true,
		})
	}

	const calculateAspectRatioFit = (srcWidth, srcHeight, maxWidth, maxHeight) => {
		const ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight)

		return { width: srcWidth * ratio, height: srcHeight * ratio }
	}

	const Button = styled.button`
		position: relative;
		left: 7px;
		border-radius: 100%;
		width: 32px;
		height: 32px;
		border: 0px;
		top: -11px;
		top: ${props => props?.aiBot ? "40px" : "-11px"};
		cursor: pointer;
		background: ${bg || '#3b96f2'};
		color: ${color || '#fff'};
	`
	const Animation = styled.div`
		border: 3px solid transparent;
		border-color: transparent ${bg || '#3b96f2'} transparent ${bg || '#3b96f2'};
		-moz-animation: rotate-loading 1.5s linear 0s infinite normal;
		-moz-transform-origin: 50% 50%;
		-o-animation: rotate-loading 1.5s linear 0s infinite normal;
		-o-transform-origin: 50% 50%;
		-webkit-animation: rotate-loading 1.5s linear 0s infinite normal;
		-webkit-transform-origin: 50% 50%;
		background: transparent;
		width: 42px;
		height: 42px;
		border-radius: 100%;
		position: absolute;
		top: ${props => props?.aiBot ? "28px !important" : "-23px !important"};
		left: 1px;
	`
	function Wrapper({ children }) {
		return <span className="wrapper-upload">{children}</span>
	}
	return (
		<Wrapper>
			{progress && <Animation aiBot={aiBot} className="loader-animation" />}
			<Button aiBot={aiBot} onClick={handleClick} className="file-upload" title={title}>
				<FontAwesomeIcon icon={faPaperclip} />
			</Button>
			<input type="file" ref={hiddenFileInput} onChange={handleChange} style={{ display: 'none' }} />
		</Wrapper>
	)
}
